<template>
  <div class="vulnerabilities">
    <ep-header v-bind="commonPageHeaderProps">
      <template #left>
        <h1>
          <span><router-link
              class="text--link"
              to="/vulnerabilities"
            >Vulnerabilities</router-link></span>
          <span>/</span>
          <span>{{ vulnerability.id }}</span>
        </h1>
      </template>
    </ep-header>
    <sidebar-layout sidebar-padding="2rem 0 0 3rem">
      <template #sidebar>
        sidebar
      </template>
      <template #content>
        <ep-container
          v-bind="commonContainerProps"
          content-padding="3rem 0 1rem 0"
        >
          <p>{{ vulnerability.id }}</p>
          <p>{{ vulnerability.published_date }}</p>
          <p>{{ vulnerability.last_modified_date }}</p>
          <p>{{ vulnerability.date_seen }}</p>
          <p>{{ vulnerability.severity.value }}</p>
          <p>{{ vulnerability.base_score }}</p>
          <p>{{ vulnerability.description }}</p>
          <p>{{ vulnerability.affected_assets }}</p>
          <!-- {{ vulnerability }} -->
        </ep-container>
        <ep-container
          v-bind="commonContainerProps"
          content-padding="3rem 0 1rem 0"
        >
          affected assets
        </ep-container>
      </template>
    </sidebar-layout>
  </div>
</template>

<script setup>
  import { computed, defineProps } from 'vue'
  import { useStore } from 'vuex'
  import SidebarLayout from '@/layouts/SidebarLayout.vue'

  const store = useStore()
  const commonPageHeaderProps = store.state.commonProps.commonPageHeaderProps
  const commonContainerProps = computed(() => store.state.commonProps.commonContainerProps)

  const vulnerability = computed(() => store.state.selectedVulnerability)

  // const { vulnerabilityID } = defineProps(['vulnerabilityID'])
  // const vulnerability = computed(() => store.getters.getVulnerabilityById(vulnerabilityID))
</script>

<style lang="scss" scoped>
  h1 {
    display: flex;
    gap: 1rem;
  }
</style>