<template>
  <div class="network">
    Service Config - Endpoint
  </div>
</template>

<script>
  export default {
    name: 'Endpoint',
  }
</script>