<template>
  <div class="alerts">
    Notifications
  </div>
</template>

<script>
  export default {
    name: 'Alerts',
  }
</script>