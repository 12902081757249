<template>
  <div :class="['logo', { 'logo--icon': type == 'icon' }]">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="119px"
      height="18px"
      viewBox="0 0 119 18"
      style="enable-background: new 0 0 119 18"
      xml:space="preserve"
    >
      <g>
        <template v-if="type == 'full'">
          <path
            style="fill: currentColor"
            d="M30.3,12.2c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.6-0.8,1.2-1.4,1.7c-0.6,0.5-1.3,0.8-2,1
		c-0.8,0.2-1.6,0.3-2.5,0.3c-1.3,0-2.4-0.2-3.5-0.5c-1-0.3-2-0.9-2.8-1.7l2.6-2.6c0.4,0.4,1,0.7,1.7,0.9c0.7,0.2,1.3,0.3,2,0.3
		c1.6,0,2.4-0.5,2.4-1.5c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.6-0.4-1.1-0.4l-2-0.3c-1.5-0.2-2.6-0.7-3.3-1.5c-0.8-0.8-1.1-1.9-1.1-3.4
		c0-0.7,0.1-1.4,0.4-2.1C19.1,3,19.5,2.5,20,2c0.6-0.5,1.2-0.9,1.9-1.1c0.7-0.3,1.6-0.4,2.5-0.4c1.2,0,2.2,0.2,3.1,0.5
		c0.9,0.3,1.8,0.8,2.4,1.5l-2.6,2.6c-0.2-0.2-0.5-0.5-0.8-0.6c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.5-0.1-0.8-0.1h-0.7
		c-0.3,0-0.6,0-0.9,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3,0.1,0.4
		c0.1,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.5,0.2,0.7,0.2l2,0.3c1.5,0.2,2.5,0.7,3.3,1.4c0.4,0.4,0.8,1,1,1.6
		C30.2,10.8,30.3,11.5,30.3,12.2z"
          />
          <path
            style="fill: currentColor"
            d="M34.2,17.3V0.6h11.2v3.7h-7.2v2.8h6.1v3.7h-6.1v2.9h7.2v3.6H34.2z"
          />
          <path
            style="fill: currentColor"
            d="M59,17.3l-5.3-8.4v8.4h-4.1V0.6h3.6L58.5,9V0.6h4.1v16.7H59z"
          />
          <path
            style="fill: currentColor"
            d="M74.4,4.3v13.1h-4.1V4.3h-4.1V0.6h12.2v3.7L74.4,4.3z"
          />
          <path
            style="fill: currentColor"
            d="M82.1,17.3V0.6h4.1v16.7H82.1z"
          />
          <path
            style="fill: currentColor"
            d="M99.2,17.3l-2.9-6.2h-1.4v6.2h-4.1V0.6h6.6c0.8,0,1.7,0.2,2.4,0.5c0.7,0.3,1.3,0.7,1.8,1.2
		c0.5,0.5,0.8,1.1,1.1,1.7c0.2,0.6,0.4,1.3,0.4,2c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.3,0.9-0.6,1.2c-0.2,0.3-0.5,0.7-0.9,0.9
		c-0.3,0.2-0.6,0.5-1,0.6l3.6,7L99.2,17.3z M99,6c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.3-0.5h-2.3v3.4h2.3c0.6,0,1-0.2,1.3-0.5
		C98.8,6.8,99,6.4,99,6L99,6z"
          />
          <path
            style="fill: currentColor"
            d="M107.3,17.3l0-16.7h11.2v3.7h-7.2v2.8h6.1v3.7h-6.1v2.9h7.2v3.7L107.3,17.3z"
          />
        </template>
        <path
          style="fill: currentColor"
          d="M12.6,2.6c-1.5-2-4.2-2-4.2-2H5.8c0,0-2.6,0-4.2,2C0.7,3.8,0.4,4.9,0.4,5.7v6.5c0,0.8,0.2,2,1.1,3.2
		c1.5,2,4.2,2,4.2,2h7.9v-3.7H6.6c0,0-1,0-1.7-0.8c-0.3-0.4-0.5-0.8-0.5-1.3v-0.8h9.3V5.7C13.7,4.9,13.5,3.8,12.6,2.6z M4.4,6.1
		c0-0.5,0.2-0.9,0.5-1.3c0.6-0.8,1.7-0.8,1.7-0.8h1c0,0,1,0,1.7,0.8c0.3,0.4,0.5,0.8,0.5,1.3v1H4.4V6.1z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'EsentireLogo',
    props: ['type']
  }
</script>

<style lang="scss">
  .logo {
    cursor: pointer;
  }

  svg {
    display: block;
  }

  .logo--icon {
    width: 40px;
    padding: 0 0 0 13px;
  }
</style>