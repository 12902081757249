const filenames = [
  'Network_Security_Overview.docx',
  'Firewall_Configuration_Report.xlsx',
  'Intrusion_Detection_Logs.csv',
  'Vulnerability_Assessment_Summary.pdf',
  'Penetration_Testing_Results.docx',
  'Access_Control_Policy.txt',
  'Incident_Response_Plan.pdf',
  'Network_Monitoring_Logs.csv',
  'Security_Policy_Review.docx',
  'Threat_Intelligence_Report.pdf',
  'Asset_Inventory.xlsx',
  'Security_Audit_Findings.docx',
  'Data_Breach_Investigation_Report.pdf',
  'Network_Topology_Diagram.xlsx',
  'Risk_Assessment_Summary.docx',
  'Compliance_Checklist.xlsx',
  'Security_Event_Logs.csv',
  'Encryption_Key_Management_Policy.pdf',
  'Network_Scanning_Results.docx',
  'Security_Awareness_Training_Materials.zip',
  'Endpoint_Security_Configuration.xlsx',
  'Disaster_Recovery_Plan.pdf',
  'Incident_Response_Playbook.docx',
  'Phishing_Simulation_Results.csv',
  'Security_Baseline_Assessment.pdf',
  'Threat_Modeling_Document.docx',
  'VPN_Configuration_Guide.pdf',
  'Network_Traffic_Analysis.xlsx',
  'Patch_Management_Policy.docx',
  'Incident_Response_Timeline.csv',
  'Security_Breach_Incident_Report.docx',
  'Social_Engineering_Assessment_Summary.pdf',
  'Data_Classification_Policy.docx',
  'Network_Security_Best_Practices.pdf'
]

export default filenames
