<template>
  <div class="network">
    Service Config - Cloud
  </div>
</template>

<script>
  export default {
    name: 'Cloud',
  }
</script>